import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from '../components/seo';

const NotFound = () => {
    return (
        <Layout>
            <Seo title="404!"/>
            <section className="notfound-container">
                <h1 className="notfound-title">Strona nie istnieje</h1>
                <Link to="/" className="notfound-link">Powrót do strony głównej</Link>
            </section>
        </Layout>
    )
}

export default NotFound;